.button-whatsapp {
  background-color: #128c7e;
  color: #fff;
  border: solid 1px;
  border-radius: 50px;
  transition-duration: 0.4s;
}

.button-whatsapp:hover {
  background-color: #075e54;
  color: #fff;
}

.logoBrancoPE {
  width: 200px;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.logoColorido {
  width: 300px;
  height: 250px;
  justify-content: center;
  align-items: center;
}

.fixedCustom {
  margin-top: 160px;
}

@media screen and (min-width: 576px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
  .fixedCustom {
    margin-top: 110px;
  }
}

@media screen and (min-width: 992px) {
  .logoColorido {
    width: 500px;
    height: 450px;
    justify-content: center;
    align-items: center;
  }
  .fixedCustom {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .logoColorido {
    width: 550px;
    height: 500px;
  }
  .fixedCustom {
    margin-top: 110px;
  }
}

@media screen and (min-width: 1440px) {
  .logoColorido {
    width: 550px;
    height: 500px;
  }
}

@media screen and (min-width: 2560px) {
  .fixedCustom {
    margin-top: 190px;
  }
}

.button-secundary-appito {
  color: #000;
  background-color: #fff;
  border-radius: 40px;
  transition-duration: 0.4s;
}
.button-secundary-appito:hover {
  color: #000;
  background-color: #c4f54b;
}

.button-error-primary-appito {
  color: #ba1a1a;
  background-color: #fff;
  border-radius: 40px;
  transition-duration: 0.4s;
}
.button-error-primary-appito:hover {
  color: #fff;
  background-color: #ba1a1a;
  border: 2px solid #c4f54b;
}

.button-error-secundary-appito {
  color: #fff;
  background-color: #ba1a1a;
  border-radius: 40px;
  transition-duration: 0.4s;
}

.button-default-appito {
  color: #000;
  background-color: #fff;
  border-radius: 40px;
  transition-duration: 0.4s;
}

.list {
  background-color: #e2e2e2;
  transition-duration: 0.2s;
  border-radius: 40px;
}

.list:hover {
  background-color: #e2e2e2;
}

.list-simple {
  background-color: #fff;
  transition-duration: 0.2s;
}

.list-simple:hover {
  background-color: #f5f5f5;
}

.button-secondary-appito {
  color: #000;
  background-color: rgb(218, 218, 218);
  border-radius: 40px;
}

.button-secondary-appito:hover {
  color: #ffffff;
  background-color: rgb(121, 120, 120);
  border-radius: 40px;
}

.button-primary-appito {
  color: #000;
  background-color: #c4f54b;
  border-radius: 40px;
}
.button-primary-appito:hover {
  color: #000;
  background-color: #79aa00;
}

.button-blocked-appito {
  color: #1b1b1b;
  /* background-color: rgba(31, 31, 31, 0.12); */
  background-color: #1f1f1f1f;

  border-radius: 40px;
}

.input-appito {
  border: 2px solid #777777;
}

.input-appito:hover {
  border: 2px solid #79aa00;
}

.input-appito-error {
  border: 2px solid #ff0000;
}

.input-appito-error:hover {
  border: 2px solid #79aa00;
}

.text-error-appito {
  color: #ff0000;
}

.text-title-Appito {
  color: #000;
  font-size: 24px;

  /* padding-top: 16px; */
  display: flex;
  align-items: center;
}

.text-subTitle-Appito {
  color: #c6c6c6;
  font-size: 20px;

  /* padding-top: 16px; */
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h5 {
  color: #777;
}

/* p {
  color: #777;
} */

.h4-PE-roxo {
  color: #777;
}
.h4-PE-rosa {
  color: #4f98bc;
}
.h4-PE-branco {
  color: #fff;
}

.toast-success-PE {
  background-color: #4f98bc;
}

/* MENU AUXILIAR */
.openAuxiliarMenu {
  width: 300px !important;
}

.closedAuxiliarMenu {
  width: 0px !important;
}

/* TODO - VERIFICAR O PQ NÃO ESTA BUSCANDO DADOS DO GLOBAL E SIM DO HEADER */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 10%;
  background-color: #0a169a;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

/* .sidenav p {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
} */

/* .sidenav p:hover {
  color: #fff;
} */

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  /* .sidenav p {
    font-size: 18px;
  } */

  .title {
    font-size: 10px;
  }
  .fixedCustom {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 15px;
  }
}
/* FIM MENU AUXILIAR */

.divisor-green-1 {
  border-bottom: 2px solid #c4f54b;
}

.divisor-white {
  border-bottom: 1px solid #fff;
}

.divisor-pink {
  border-bottom: 1px solid #4f98bc;
}

/* APPITO JR inicio */

.SCH-input-appito {
  background-color: #ededed;
  border-radius: 24px;
  height: 40px;
}

.SCH-divisor-littler-black {
  border-bottom: 1px solid #000;
  width: 350px;
}

.SCH-divisor-grey {
  border-bottom: 1px solid #b1b1b1;
  width: 1040px;
}

.SCH-divisor-green {
  border-bottom: 2px solid #c2c8b4;
  width: 990px;
}

.SCH-divisor-green-modal {
  border-bottom: 2px solid #c2c8b4;
  width: 810px;
}

.SCH-divisor-loader {
  height: 2px;
  /* width:130px; */
  background: linear-gradient(#79aa00 0 0), linear-gradient(#79aa00 0 0),
    #c2c8b4;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
  width: 990px;
}

.statusGreen {
  background-color: #d7e6b3;
  color: #79aa00;
  border-radius: 6px;
}

.statusOrange {
  background-color: #fde9ca;
  color: #e29855;
  border-radius: 6px;
}

.statusRed {
  border-radius: 20px;
  color: #dd7272;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

.statusBlock {
  color: #777777;
  border-radius: 6px;
  border: 1px solid #777777;
}

.statusGrey {
  border-radius: 20px;
  color: #9b9b9b;
  background-color: #ededed;
  border: 0px;
  height: 21px;
}

.componentBadge {
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 6px;
  margin-left: 0px;
  max-height: 36px;
  white-space: nowrap;
}

.SCH-pointer-badge {
  cursor: pointer;
}

.SCH-title-modal {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 14px;

  /* TODO: TRATAR TITULO DOS MODAIS - FUNDO ERRADO */
  /* background-color: #5E5E5E1C; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;

  color: #1b1b1b;
}

.SCH-text12 {
  font-size: 12px;
  color: #828282;
}

.SCH-text14 {
  font-size: 14px;
  color: #474747;
}

.SCH-list {
  background-color: #f4f4f4;
  border-radius: 10px;
  border: 0px;
}

.SCH-divisor-modal-document {
  margin-top: 12px;
}

.SCH-center-modal-document {
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
}

.SCH-button-download-document {
  padding-left: 56px;
}

/* APPITO JR fim */

/* CUPONS */

.coupon-disponivel {
  border-radius: 20px;
  color: #79aa00;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

.coupon-cancelado {
  border-radius: 20px;
  color: #dd7272;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

.coupon-expirado {
  border-radius: 20px;
  color: #9b9b9b;
  background-color: #ededed;
  border: 0px;
  height: 21px;
}

.coupon-agendado {
  border-radius: 20px;
  color: #287cca;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

/* CUPONS fim */

/* CONTRATOS */

.BCS-status-PE {
  border-radius: 20px;
  color: #ffffff;
  background-color: #dd7272;
  border: 0px;
  height: 21px;
}

.BCS-status-PF {
  border-radius: 20px;
  color: #e29855;
  background-color: #ffddd2;
  border: 0px;
  height: 21px;
}

.BCS-status-PM {
  border-radius: 20px;
  color: #dd7272;
  background-color: #fde9ca;
  border: 0px;
  height: 21px;
}

.BCS-status-MS {
  border-radius: 20px;
  color: #79aa00;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

.BCS-status-SE {
  border-radius: 20px;
  color: #79aa00;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}
.BCS-status-VE {
  border-radius: 20px;
  color: #ffffff;
  background-color: #dd7272;
  border: 0px;
  height: 21px;
}

.BCS-status-PA {
  border-radius: 20px;
  color: #79aa00;
  background-color: #f5f5f5;
  border: 0px;
  height: 21px;
}

.BCS-select {
  border-radius: 20px;
  background-color: #e2e2e2;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  height: 28px;
}

.BCS-divisor-grey {
  border-bottom: 1px solid #b1b1b1;
  width: 1040px;
}

.BCS-divisor-loader {
  height: 3px;
  /* width:130px; */
  background: linear-gradient(#173016 0 0), linear-gradient(#173016 0 0),
    #f1f3ee;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
}

.text-lato-16 {
  font-family: Lato;
  font-size: 16px;
}

.text-lato-14 {
  font-family: Lato;
  font-size: 14px;
}

.text-lato-12 {
  font-family: Lato;
  font-size: 12px;
}

.text-roboto-16 {
  font-family: Roboto;
  font-size: 16px;
}

.text-roboto-14 {
  font-family: Roboto;
  font-size: 14px;
}

.text-roboto-12 {
  font-family: Roboto;
  font-size: 12px;
}

/* FIM CONTRATOS */
/* ANIMAÇÃO loader EM CSS PURO */

.divisor-loader {
  height: 2px;
  /* width:130px; */
  background: linear-gradient(#55a700 0 0), linear-gradient(#55a700 0 0),
    #c4f54b;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
}

@keyframes p6 {
  100% {
    background-position: 250% 0, 250% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  0% {
    background-position: -150% 0, -150% 0;
  }
}

/* link de acesso: https://www.cssportal.com/css-loader-generator/
 */
/* ANIMAÇÃO LOADER EM CSS PURO */

@media screen and (max-width: 576px) {
  .text-title-Appito {
    font-size: 20px;
  }

  .text-subTitle-Appito {
    color: #777777;
    font-size: 14px;
  }

  .button-primary-appito {
    font-size: 14px;
  }

  .button-secundary-appito {
    font-size: 14px;
  }

  .input-appito {
    font-size: 12px;
  }
  /* p {
    font-size: 12px;
  } */

  .logoBrancoPE {
    width: 130px;
    height: 90px;
    justify-content: center;
    align-items: center;
  }
  .fixedCustom {
    margin-top: 70px;
  }
}

@media screen and (max-width: 300px) {
  .text-title-Appito {
    font-size: 15px;
  }

  .button-primary-appito {
    font-size: 10px;
  }

  .button-secundary-appito {
    font-size: 10px;
  }

  .input-appito {
    font-size: 10px;
  }

  /* p {
    font-size: 10px;
  } */
}

.cardPlan {
  /* TODO - VERIFICAR O PQ DO RADIOS NÃO ESTÁ FUNCIONANDO */
  border-radius: 25px;
  border: 2px solid transparent;

  border-image: linear-gradient(45deg, #79aa00, #ffffff, #c4f54b);
  border-image-slice: 1;
}

.cardPlan:hover {
  background-color: #f5f5f5;
  border-radius: 25px;
  border: 2px solid transparent;

  border-image: linear-gradient(45deg, #79aa00, #000, #c4f54b);
  border-image-slice: 1;

  transition: 0.3s;
}
/* CARREGAMENTO BOLINHA BRANCA BARRA CSS PURO */
/* https://projects.lukehaas.me/css-loaders/ */

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #000;
  font-size: 3px;
  margin: 2px auto;
  margin-bottom: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* ANIMAÇÃO TEXTO EM CSS PURO */

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:19:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */

.tracking-in-expand-fwd {
  -webkit-animation: tracking-in-expand-fwd 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
    transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
    transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:55:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.tracking-in-expand-fwd-top {
  -webkit-animation: tracking-in-expand-fwd-top 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:56:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:57:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.tracking-in-contract-bck-top {
  -webkit-animation: tracking-in-contract-bck-top 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck-top 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:58:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/* ANIMAÇÃO BOTÃO EM CSS PURO */
.shake-horizontal {
  -webkit-animation: shake-horizontal 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:24:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

/* ANIMAÇÃO SLIDE EM CSS PURO */

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-17 0:39:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
