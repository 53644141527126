.items-check {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 42px;
  text-align: center;
  align-items: center;
}

.custom-item {
  align-items: center!important;
  height: 50px;
  padding-top: 4px;
  cursor: pointer;
}

.custom-item:hover {
  background-color: #f7f7f7;
}

.custom-item p {
  margin-bottom: 0;
}

.items-divisor {
  border-bottom: 1px solid #e1e1e1;
}

.item-active {
  border-radius: 20px;
  color: #79AA00;
  padding: 2px 8px;
  font-size: 14px;
  background-color: #F5F5F5;
}


.item-inactive {
  border-radius: 20px;
  color: #dd7272;
  padding: 2px 8px;
  font-size: 14px;
  background-color: #F5F5F5;
}

.items-text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sub-info-item {
   color: #494C45;
   font-size: 12px;
}