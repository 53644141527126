.coupon-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 42px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  padding-top: 4px;
}

.check-width {
  width: 4%;
}

.B2B-text {
  width: 40px;
  margin-left: 6px;
  margin-top: 2px;
}

.badges-billing-column {
  width: 12%;
  height: 20px;
}

.badges-actual-billing-column {
  width: 18%;
  height: 20px;
}

.default-column {
  width: 14%;
  height: 24px;
}

.coupon-info {
  padding-top: 0px;
  margin-top: 0px;
  display: flex;
  justify-content: start;
  font-size: 12px;
  color: #494c45;
}

.coupon-divisor-grey {
  padding-top: 4px;
  border-bottom: 1px solid #e2e2e2;
}

.coupon-code-with-info {
  height: 40px;
  width: 20%;
  padding-right: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.coupon-spaceType-with-info {
  width: 24%;
  height: 40px;
}
