custom-control-input {
  background-color: #000;
}

custom-control-input:checked {
  background-color: #000;
}

.show-header {
  color: #e2e2e2;
  font-size: 16px;
  width: 100%;
  letter-spacing: 0em;
  text-align: left;
}

.filter-coupon-arena-list {
  width: 166px;
  margin-top: 10px;
  padding: 0px;
  margin-left: 8px;
}

.category-list {
  width: 176px;
  margin-top: 10px;
  padding: 0px;
  margin-left: 8px;
}

.spaces-list {
  width: 166px;
  margin-top: 10px;
  padding: 0px;
  margin-left: 8px;
}

.status-list {
  width: 148px;
  margin-top: 10px;
  padding-left: 4px;
  padding: 0px;
  margin-left: 8px;
}
.type-list {
  width: 140px;
  margin-top: 10px;
  padding-left: 4px;
  padding: 0px;
  margin-left: 8px;
}

.header-list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-checked {
  display: flex;
  justify-content: center;
  width: 4%;
}

.name-column {
  width: 36%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-12 {
  width: 12%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-14 {
  width: 14%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-18 {
  width: 18%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.coupon {
  background-color: red;
}

.search-coupon {
  display: flex;
  border-radius: 100px !important;
  border: 1px;

  height: 28px;
  margin-top: 10px;
  padding: 0px;
  margin-left: 8px;
  width: auto;
}

.input-coupon {
  border-radius: 4px 0 0 4px;
  width: 176px;
  height: 28px;
}

.icon-coupon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background-color: #000;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  cursor: pointer;
}

.coupon-info-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000;
}

.coupon-info-subtitle {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #494c45;
}

.divisor-coupon {
  border-bottom: 1px solid #e2e2e2;
}

.coupon-code {
  height: 20px;
  width: 20%;
  padding-right: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.coupon-value {
  width: 14%;
  height: 24px;
}

.coupon-userType {
  width: 14%;
  height: 24px;
}

.coupon-bookingType {
  width: 14%;
  height: 24px;
}

.coupon-spaceType {
  width: 24%;
  height: 24px;
}

.coupon-status {
  width: 10%;
  height: 24px;
}

.coupon-divisor-green-modal {
  padding-top: 12px;
  border-bottom: 2px solid #c2c8b4;
  width: 700px;
}
.coupon-divisor-green-cancel-modal {
  padding-top: 12px;
  border-bottom: 2px solid #c2c8b4;
  width: 440px;
}

.coupon-modal-line-1 {
  width: 50%;
}

.coupon-modal-line-2 {
  width: 50%;
  margin-top: 20px;
}

.coupon-modal-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0px;
}

.styleAddInfo {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fcfcfc;
  border: 0;
  padding: 16px; /* Convertido para pixels (4 * 4 = 16) */
  position: absolute;
  width: 768px;
  min-height: 524px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}

.modal-cancel-desc {
  margin-top: 35px;
  color: #494c45;
}

.modal-header-text {
  color: #1e201a;
}

.pagination-info {
  /* background-color: red; */
  display: flex;
  justify-content: start;
  /* margin-top: 20px; */
  align-items: center;
  width: 20%;
}

.pagination {
  /* background-color: #000; */
  display: flex;
  justify-content: end;
  padding-top: 8px;
  padding-right: 0px;
  width: 80%;
}

.pagination-organize {
  display: flex;
}

.pagination-number-choice {
  background-color: #7b7e75;
  color: #fff;
  cursor: pointer;
}

.pagination-number {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.button-pagination {
  background-color: #fff;
  color: #000;
  border: 1px solid black;
  font-size: 22px;
}

.button-pagination:hover {
  background-color: #7b7e75;
  color: #fff;
  border: 1px solid black;
  font-size: 22px;
}

.coupon-divisor-loader {
  height: 3px;
  /* width:130px; */
  background: linear-gradient(#173016 0 0), linear-gradient(#173016 0 0),
    #f1f3ee;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
}
