@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css');
/* header {
  background-color: blueviolet;
  color: white;
} */

.title {
  font-size: 20px;
  color: #4f98bc;
}

.divisor {
  border-bottom: 1px solid #777777;
}

.openPrincipalMenu {
  width: 300px !important;
}

.closedPrincipalMenu {
  width: 0px !important;
}

/* logica menu lateral */

body {
  font-family: 'Lato', sans-serif;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #000;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav p {
  padding: 4px 4px 4px 20px;
  text-decoration: none;
  font-size: 20px;
  color: #777777;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

/* .sidenav p:hover {
  background-color: #fff;
} */

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav p {
    font-size: 18px;
  }

  .title {
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 15px;
  }
}
