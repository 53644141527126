.check {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 42px;
  text-align: center;
  align-items: center;
}

.check-width {
  width: 4%;
}

.B2B-flag {
  height: 24px;
  width: 36%;
  padding-right: 10px;
}

.B2B-text {
  width: 40px;
  margin-left: 6px;
  margin-top: 2px;
}

.badges-billing-column {
  width: 12%;
  height: 20px;
}

.badges-actual-billing-column {
  width: 18%;
  height: 20px;
}

.default-column {
  width: 14%;
  height: 24px;
}
