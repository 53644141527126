custom-control-input {
  background-color: #000;
}

custom-control-input:checked {
  background-color: #000;
}

.show-header {
  color: #e2e2e2;
  font-size: 16px;
  width: 100%;
  letter-spacing: 0em;
  text-align: left;
}

.status-list {
  width: 100px;
  margin-top: 10px;
}

.sport-list {
  width: 162px;
  margin-top: 10px;
  padding-left: 4px;
}

.month-list {
  width: 150px;
  margin-top: 10px;
  padding-left: 4px;
}

.contract-list {
  width: 136px;
  margin-top: 10px;
  padding-left: 4px;
}

.header-list {
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-checked {
  display: flex;
  justify-content: center;
  width: 4%;
}

.name-column {
  width: 36%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-12 {
  width: 12%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-14 {
  width: 14%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.default-column-18 {
  width: 18%;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.pagination-number-choice {
  background-color: #173016!important;
  color: #fff!important;
}

.pagination-number-choice:hover {
  color: #fff!important;
}

.page-link:hover {
  color: inherit
}

.header-list-items {
  width: 100%;
}