.logoColorido {
  width: 450px;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 576px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 992px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .logoColorido {
    width: 450px;
    height: 400px;
    width: 100%;
  }
}
