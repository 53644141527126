.coupon-date {
  width: 50%;
  padding: 0px;
  margin: 0px;
}

.coupon-line {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.coupon-check {
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
}

.coupon-user {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.coupon-radio-booking-type {
  margin-top: 12px;
  padding-right: 28px;
  display: flex;
  justify-content: center;
}

.coupon-radio-booking-type-choice {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.coupon-radio-discount {
  margin-top: 12px;
  padding-right: 42px;
  display: flex;
  justify-content: center;
}

.coupon-radio-limits {
  margin-top: 12px;
  padding-right: 18px;
  display: flex;
  justify-content: center;
}
.coupon-weekdays {
  padding-left: 20px;
  padding-right: 50px;
}

.coupon-desc {
  padding-left: 20px;
  padding-right: 40px;
}

.coupon-day {
  display: flex;
  justify-content: start;
}

.title-description {
  display: flex;
  font-family: Roboto;
  font-size: 16px;
}

.title-description-disabled {
  display: flex;
  color: #494c45;
  font-family: Roboto;
  font-size: 16px;
}

.coupon-message-wrong {
  display: flex;
  margin-top: 12px;
  color: red;
}

.coupon-footer {
  display: flex;
  justify-content: center;
}

.coupon-arena-list {
  margin-right: 60px;
  margin-top: 12px;
  display: flex;
  justify-content: start;
}

.space-list {
  margin-right: 28px;
  margin-top: 12px;
  display: flex;
  justify-content: start;
}

.check-all {
  padding-left: 12px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.user-radio {
  padding-right: 80px;
  display: flex;
  justify-content: start;
}

.choice-phone {
  width: 45%;
}

.choice-nickname {
  width: 50%;
}

.mini-divisor-coupon-green {
  border-bottom: 3px solid #d7e6b3;
  width: 360px;
}

.mini-divisor-position {
  display: flex;
  justify-content: center;
}

@media (max-width: 1440px) {
  .coupon-weekdays {
    padding-left: 10px;
    padding-right: 40px;
  }

  .user-radio {
    padding-right: 12px;
    display: flex;
    justify-content: start;
  }
}

@media (max-width: 1024px) {
  .coupon-weekdays {
    padding-left: 10px;
    padding-right: 40px;
  }

  .user-radio {
    padding-right: 20px;

    display: flex;
    justify-content: start;
  }
}

@media (max-width: 768px) {
  .coupon-weekdays {
    padding-left: 10px;
    padding-right: 50px;
  }

  .user-radio {
    padding-right: 38px;
    display: flex;
    justify-content: start;
  }
}
